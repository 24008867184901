import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    public loadingController: LoadingController,
    public toastController: ToastController
  ) { }

  ngOnInit() { }

  async loginToDashboard() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
    });
    loading.present().then(() => {
      this.afAuth.signInWithEmailAndPassword(this.email, this.password).then((authRes) => {
        console.log(authRes.user);
        this.router.navigate(['/admin']);
        this.presentToast('Welcome TsurugaKj Boss Lady', 'success');
        loading.dismiss();
      }).catch((err) => {
        console.log(err);
        this.presentToast('Login failed check user credentials', 'danger');
        loading.dismiss();
      });
    });
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
      color,
      position: 'top'
    });
    toast.present();
  }

}
