import { Component } from '@angular/core';

import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationEnd, Router } from '@angular/router';

import { AlertController, ToastController } from '@ionic/angular';
import { PushMessageService } from 'src/app/services/push-message.service';

declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  fcmToken: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private messagingService: PushMessageService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private mdlCtrl: ModalController

  ) {
    this.initializeApp();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-KS1W7J5B10',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    }
    );

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      //this.requestPermission();
    });
  }

  requestPermission() {
    this.messagingService.requestPermission().subscribe(async (res) => {
      if (res) {
        const data = {
          token: res
        }
        this.fcmToken = data;
        this.checktoken();
      } else {
        return;
      }
    }
    );
  }


  async checktoken() {
    if (localStorage.getItem('fcmToken')) {
      return;
    } else {
      localStorage.setItem('fcmToken', JSON.stringify(this.fcmToken));
      this.storeTokenToDb(this.fcmToken);
      this.presentToast();
    }
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Notifications are enabled You will recieve latest novel updates',
      duration: 3000,
      color: 'primary'
    });
    toast.present();
  }

  storeTokenToDb(token: {}) {
    this.messagingService.saveTokenForNotifications(token).then((res) => {
      console.log(res);
    });
  }

  async deleteToken() {
    this.messagingService.deleteToke();
    const toast = await this.toastCtrl.create({
      message: 'Token removed',
      duration: 2000
    });
    toast.present();
  }
}
