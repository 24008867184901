import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { tap } from 'rxjs/operators';
import { DocumentCollection } from '../models/document.collection';

@Injectable({
  providedIn: 'root'
})
export class PushMessageService {
  token = null;

  currentMessage = new BehaviorSubject(null);

  constructor(private afmessaging: AngularFireMessaging, private afs: AngularFirestore) {

  }

  requestPermission() {
    return this.afmessaging.requestToken;

  }

  getMessages() {
    return this.afmessaging.messages;
  }

  deleteToke() {
    if (this.token) {
      this.afmessaging.deleteToken(this.token);
      this.token = null;
    }
  }

  saveTokenForNotifications(token: {}) {
    return this.afs.collection(DocumentCollection.TokensFcm).add(token);
  }



}
