// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBzCdxarK60dyafIztT5UwB_6zylxvP988",
    authDomain: "tsurugakj.firebaseapp.com",
    databaseURL: "https://tsurugakj.firebaseio.com",
    projectId: "tsurugakj",
    storageBucket: "tsurugakj.appspot.com",
    messagingSenderId: "560141579578",
    appId: "1:560141579578:web:19bf5b4694507e2847cc03",
    measurementId: "G-1N1B7VBY1K"
  },
  // shopify_API:'f623f6e3e4edc29eb9d2062447a4f120',
  // shopify_secret:'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',
  // shopify_host:'minikuu.myshopify.com',
  domain: 'minikuu.myshopify.com',
  storefrontAccessToken: '50b1e2f73d150648b56142ef3762643d'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
